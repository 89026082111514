var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentSlide)?_c('header',{staticClass:"c-frontpage-header"},[_c('div',{staticClass:"w-visual-screen h-full flex-shrink-0 overflow-hidden"},[_c('div',{staticClass:"relative grid grid-cols-5 >=768:grid-cols-12 auto-rows-fr gap-x-layout-gutter w-full h-full max-w-layout-max px-layout-margin mx-auto"},[_c('LensFlare',{class:[
					'c-frontpage-header__lens-flare-desktop',
					'absolute left-0 bottom-0 hidden >=768:block',
					'transform translate-y-1/4',
				],staticStyle:{"width":"700px","height":"700px"},attrs:{"id":"c-frontpage-header__flare","angle":9,"min-blur-amount":20,"max-blur-amount":30,"easing":(x) => x ** 2.5 * 1.3,"flares":[
					{
						size: 1200,
						targetSize: 1000,
						offset: -50,
						targetOffset: -100,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 800,
						offset: -500,
						targetOffset: -800,
						theme: 'secondary',
						opacity: 0.5,
					},
					{
						size: 600,
						targetSize: 400,
						offset: 600,
						targetOffset: 800,
						theme: 'secondary',
						opacity: 0.8,
					},
				]}}),_vm._v(" "),_c('LensFlare',{class:[
					'c-frontpage-header__lens-flare-mobile',
					'absolute right-0 bottom-0 >=768:hidden',
					'transform translate-y-1/4',
				],staticStyle:{"width":"300px","height":"300px"},attrs:{"id":"c-frontpage-header__mobile","angle":18,"min-blur-amount":15,"max-blur-amount":15,"flares":[
					{
						size: 1400,
						targetSize: 1000,
						offset: -50,
						targetOffset: -100,
						theme: 'primary',
						opacity: 1,
					},
					{
						size: 1000,
						offset: -500,
						targetOffset: -800,
						theme: 'secondary',
						opacity: 0.5,
					},
					{
						size: 800,
						targetSize: 400,
						offset: 600,
						targetOffset: 800,
						theme: 'secondary',
						opacity: 0.8,
					},
				]}}),_vm._v(" "),_c('div',{ref:"desktopImage",staticClass:"hidden >=768:block col-start-4 col-end-13 row-start-1 row-end-3",staticStyle:{"min-height":"min(200px, 14vw)"}},[_c('div',{staticClass:"w-full",staticStyle:{"height":"min(clamp(438px, 72vw, 600px), 250%)","margin-bottom":"-100%"}},[_c('TransitionExt',{attrs:{"name":"frontpage-header__content","duration":"500","mode":"out-in"}},[(_vm.currentImage)?_c('UmbracoImageExt',{key:'image-' + _vm.currentIndex,staticClass:"c-frontpage-header__image w-full h-full",attrs:{"image-class-names":"object-cover","widths":[375, 500, 700, 1040],"sizes":_vm.imageSizes({
									default: '100vw',
									768: '72vw',
								}),"alt":_vm.currentImage.altText,"source-url":_vm.currentImage.cropUrl,"source-width":_vm.currentImage.width,"source-height":_vm.currentImage.height,"aspect-ratio":1040 / 600,"lazyload":false}}):_c('span')],1)],1)]),_vm._v(" "),_c('div',{ref:"mobileImage",staticClass:">=768:hidden col-start-1 col-end-6 row-start-1 row-end-4"},[_c('div',{staticClass:"w-full",style:(_vm.currentImage && 'height: 438px; margin-bottom: -25%')},[_c('TransitionExt',{attrs:{"name":"frontpage-header__content","duration":"500","mode":"out-in"}},[(_vm.currentImage)?_c('UmbracoImageExt',{key:'image-' + _vm.currentIndex,staticClass:"c-frontpage-header__image -mx-layout-margin w-visual-screen h-full",attrs:{"image-class-names":"object-cover","widths":[375, 500, 700, 768],"sizes":_vm.imageSizes({
									default: '100vw',
								}),"alt":_vm.currentImage.altText,"source-url":_vm.currentImage.cropUrl,"source-width":_vm.currentImage.width,"source-height":_vm.currentImage.height,"aspect-ratio":375 / 438,"lazyload":false}}):_c('span')],1)],1)]),_vm._v(" "),_c('div',{ref:"content",staticClass:"relative col-start-1 col-end-6 >=768:col-end-9 row-start-4 >=768:row-start-3 row-end-7 >=768:pr-1/12col"},[_c('LinkTile',{staticClass:"group grid grid-cols-7 gap-x-layout-gutter gap-y-md w-full h-full px-layout-gutter >=768:pl-1/12col pt-3xl pb-4xl bg-secondary transform -translate-x-layout-margin >=768:translate-x-0",attrs:{"link-tag":_vm.currentLink ? 'NuxtLinkExt' : 'div',"aria-labelledby":_vm.currentLink ? 'frontpage-header__title' : null,"to":_vm.currentLink ? _vm.currentLink.url : null,"link-partials-query":_vm.currentLink
							? 'h1, .c-frontpage-header__link'
							: '.nothing'}},[(_vm.slides.length > 1)?_c('nav',{staticClass:"mt-auto >=768:mt-0 col-span-4 >=768:col-span-6 row-start-4 >=768:row-start-1 h-40 flex gap-x-sm"},_vm._l((_vm.slides),function(slide,index){return _c('button',{key:index,staticClass:"bg-black w-12 h-12 mt-auto -mr-2 rounded-full transition-opacity duration-200",class:index !== _vm.currentIndex && 'opacity-40',attrs:{"aria-current":index === _vm.currentIndex ? 'true' : null,"aria-label":`Gå til slide ${index + 1} af ${
								_vm.slides.length
							}`},on:{"click":() => _vm.goto(index)}})}),0):_vm._e(),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"frontpage-header__content","duration":"500","mode":"out-in"},on:{"before-leave":_vm.onSlideLeave,"enter":_vm.onSlideEnter}},[(_vm.currentTitle)?_c('h1',{key:'title-' + _vm.currentIndex,staticClass:"transform duration-500 group-data-hover:translate-x-8 font-darker-grotesque font-bold text-h1-frontpage col-span-6 row-start-2",attrs:{"id":"frontpage-header__title"},domProps:{"textContent":_vm._s(_vm.currentTitle)}}):_c('span')]),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"frontpage-header__content","duration":"500","mode":"out-in"}},[(_vm.currentTeaser)?_c('p',{key:'teaser-' + _vm.currentIndex,staticClass:"mt-8 text-teaser-frontpage font-darker-grotesque whitespace-pre-wrap col-span-6 row-start-3",domProps:{"innerHTML":_vm._s(_vm.currentTeaser)}}):_c('span')]),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"frontpage-header__content","duration":"500","mode":"out-in"}},[(_vm.currentLink)?_c('span',{key:'arrow-' + _vm.currentIndex,staticClass:"mt-auto row-start-4 >=768:row-start-3 col-start-7"},[_c('span',{class:[
									'c-frontpage-header__link',
									'inline-flex w-40 h-40',
									'rounded-full bg-text text-white',
								]},[_c('SvgArrow',{staticClass:"mx-auto w-20"})],1)]):_c('span')])],1)],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
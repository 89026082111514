<template>
	<article class="d-front-page-custom">
		<div class="flex justify-center">
			<Component
				:is="
					hero.layout === 'small'
						? 'FrontpageHeader'
						: 'FrontpageHeaderWide'
				"
				class="w-full max-w-layout-max flex-shrink-0"
				v-bind="hero"
			/>
		</div>

		<BlockList
			v-if="contentElements && contentElements.length"
			class="relative mt-5xl"
			:block-list="contentElements"
		/>

		<FeedbackModule
			v-if="!hideFeedback"
			:feedback="feedback"
			class="mt-7xl"
		/>
	</article>
</template>

<script>
import FrontpageHeader from '~/components/subsite/FrontpageHeader';
import FrontpageHeaderWide from '~/components/subsite/FrontpageHeaderWide';
import BlockList from '~/components/subsite/BlockList';
import FeedbackModule from '~/components/shared/FeedbackModule';

export default {
	name: 'SubsiteFrontPageCustom',
	components: {
		FrontpageHeader,
		FrontpageHeaderWide,
		BlockList,
		FeedbackModule,
	},
	inheritAttrs: false,

	props: {
		contentElements: {
			type: Array,
			default: () => [],
		},
		hero: {
			type: Object,
			default: () => ({}),
		},
		hideFeedback: {
			type: Boolean,
			default: false,
		},
		feedback: Object,
	},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentSlide)?_c('header',{staticClass:"c-frontpage-header-wide"},[_c('div',{staticClass:"w-visual-screen h-full flex-shrink-0 overflow-hidden"},[_c('LinkTile',{staticClass:"relative w-full h-full bg-black max-w-layout-max mx-auto",attrs:{"link-tag":_vm.currentLink ? 'NuxtLinkExt' : 'div',"aria-labelledby":_vm.currentLink ? 'frontpage-header-wide__title' : null,"to":_vm.currentLink ? _vm.currentLink.url : null,"link-partials-query":_vm.currentLink
					? 'h1, .c-frontpage-header-wide__link'
					: '.nothing'}},[_c('div',{ref:"desktopImage",staticClass:"absolute left-0 top-0 w-full h-full hidden >=768:block"},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500","mode":"out-in"}},[(_vm.currentImage)?_c('UmbracoImageExt',{key:'image-' + _vm.currentIndex,staticClass:"c-frontpage-header-wide__image w-full h-full",attrs:{"image-class-names":"object-cover","widths":[375, 500, 700, 1040, 1200, 1440],"sizes":_vm.imageSizes({
								default: '100vw',
								1440: '1440px',
							}),"alt":_vm.currentImage.altText,"source-url":_vm.currentImage.cropUrl,"source-width":_vm.currentImage.width,"source-height":_vm.currentImage.height,"aspect-ratio":1440 / 749,"lazyload":false}}):_c('span')],1)],1),_vm._v(" "),_c('div',{ref:"mobileImage",staticClass:"absolute left-0 top-0 w-full h-full >=768:hidden"},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"500","mode":"out-in"}},[(_vm.currentImage)?_c('UmbracoImageExt',{key:'image-' + _vm.currentIndex,staticClass:"c-frontpage-header-wide__image w-full h-full",attrs:{"image-class-names":"object-cover","widths":[375, 500, 700, 768],"sizes":_vm.imageSizes({
								default: '100vw',
							}),"alt":_vm.currentImage.altText,"source-url":_vm.currentImage.cropUrl,"source-width":_vm.currentImage.width,"source-height":_vm.currentImage.height,"aspect-ratio":375 / 557,"lazyload":false}}):_c('span')],1)],1),_vm._v(" "),_c('div',{staticClass:"absolute left-0 top-0 w-full h-full bg-black bg-opacity-40"}),_vm._v(" "),_c('div',{ref:"content",staticClass:"relative w-full h-full px-layout-margin"},[_c('div',{staticClass:"flex flex-col justify-center items-center w-full h-full >=768:px-2/12col pt-5xl pb-5xl text-white"},[_c('TransitionExt',{attrs:{"name":"frontpage-header-wide__content","duration":"500","mode":"out-in"},on:{"before-leave":_vm.onSlideLeave,"enter":_vm.onSlideEnter}},[(_vm.currentTitle)?_c('h1',{key:'title-' + _vm.currentIndex,staticClass:"font-darker-grotesque font-bold text-h1-frontpage text-center mb-xl",class:_vm.slides.length && 'mt-auto',attrs:{"id":"frontpage-header-wide__title"},domProps:{"textContent":_vm._s(_vm.currentTitle)}}):_c('span')]),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"frontpage-header-wide__content","duration":"500","mode":"out-in"}},[(_vm.currentLink)?_c('span',{key:'arrow-' + _vm.currentIndex},[_c('span',{class:[
									'c-frontpage-header-wide__link',
									'inline-flex w-40 h-40',
									'rounded-full bg-white text-text',
								]},[_c('SvgArrow',{staticClass:"mx-auto w-20"})],1)]):_c('span')]),_vm._v(" "),(_vm.slides.length > 1)?_c('nav',{staticClass:"mt-auto mb-lg self-end transform >=768:translate-x-2/12col flex gap-x-sm"},_vm._l((_vm.slides),function(slide,index){return _c('button',{key:index,staticClass:"bg-white w-12 h-12 -mr-2 rounded-full transition-opacity duration-200",class:index !== _vm.currentIndex && 'opacity-40',attrs:{"aria-current":index === _vm.currentIndex ? 'true' : null,"aria-label":`Gå til slide ${index + 1} af ${
								_vm.slides.length
							}`},on:{"click":() => _vm.goto(index)}})}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute bottom-0 left-layout-margin right-0 h-5xl bg-white overflow-hidden"},[_c('LensFlare',{class:[
								'c-frontpage-header-wide__lens-flare-desktop',
								'transform -translate-y-1/2 z-10',
							],staticStyle:{"width":"min(","height":"min("},attrs:{"id":"c-frontpage-header-wide__flare","angle":9,"min-blur-amount":12,"max-blur-amount":14,"easing":(x) => x ** 2.5 * 1.3,"flares":[
								{
									size: 800,
									targetSize: 1000,
									offset: 160,
									targetOffset: 180,
									theme: 'primary',
									opacity: 1,
								},
								{
									size: 300,
									offset: -250,
									targetOffset: -300,
									theme: 'secondary',
									opacity: 0.5,
								},
								{
									size: 600,
									targetSize: 400,
									offset: 700,
									targetOffset: 800,
									theme: 'secondary',
									opacity: 0.8,
								},
							]}})],1)],1)])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
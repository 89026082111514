<template>
	<header v-if="currentSlide" class="c-frontpage-header-wide">
		<div class="w-visual-screen h-full flex-shrink-0 overflow-hidden">
			<LinkTile
				:link-tag="currentLink ? 'NuxtLinkExt' : 'div'"
				:aria-labelledby="
					currentLink ? 'frontpage-header-wide__title' : null
				"
				:to="currentLink ? currentLink.url : null"
				:link-partials-query="
					currentLink
						? 'h1, .c-frontpage-header-wide__link'
						: '.nothing'
				"
				class="relative w-full h-full bg-black max-w-layout-max mx-auto"
			>
				<!-- Header Image Desktop -->
				<div
					ref="desktopImage"
					class="
						absolute
						left-0
						top-0
						w-full
						h-full
						hidden
						>=768:block
					"
				>
					<TransitionExt
						name="fade-in-out"
						duration="500"
						mode="out-in"
					>
						<UmbracoImageExt
							v-if="currentImage"
							:key="'image-' + currentIndex"
							class="c-frontpage-header-wide__image w-full h-full"
							image-class-names="object-cover"
							:widths="[375, 500, 700, 1040, 1200, 1440]"
							:sizes="
								imageSizes({
									default: '100vw',
									1440: '1440px',
								})
							"
							:alt="currentImage.altText"
							:source-url="currentImage.cropUrl"
							:source-width="currentImage.width"
							:source-height="currentImage.height"
							:aspect-ratio="1440 / 749"
							:lazyload="false"
						/>
						<span v-else></span>
					</TransitionExt>
				</div>

				<!-- Header Image Mobile -->
				<div
					ref="mobileImage"
					class="absolute left-0 top-0 w-full h-full >=768:hidden"
				>
					<TransitionExt
						name="fade-in-out"
						duration="500"
						mode="out-in"
					>
						<UmbracoImageExt
							v-if="currentImage"
							:key="'image-' + currentIndex"
							class="c-frontpage-header-wide__image w-full h-full"
							image-class-names="object-cover"
							:widths="[375, 500, 700, 768]"
							:sizes="
								imageSizes({
									default: '100vw',
								})
							"
							:alt="currentImage.altText"
							:source-url="currentImage.cropUrl"
							:source-width="currentImage.width"
							:source-height="currentImage.height"
							:aspect-ratio="375 / 557"
							:lazyload="false"
						/>
						<span v-else></span>
					</TransitionExt>
				</div>

				<!-- Header Content -->
				<div
					class="
						absolute
						left-0
						top-0
						w-full
						h-full
						bg-black bg-opacity-40
					"
				></div>
				<div
					ref="content"
					class="relative w-full h-full px-layout-margin"
				>
					<div
						class="
							flex flex-col
							justify-center
							items-center
							w-full
							h-full
							>=768:px-2/12col
							pt-5xl
							pb-5xl
							text-white
						"
					>
						<!-- Heading -->
						<TransitionExt
							name="frontpage-header-wide__content"
							duration="500"
							mode="out-in"
							@before-leave="onSlideLeave"
							@enter="onSlideEnter"
						>
							<h1
								v-if="currentTitle"
								id="frontpage-header-wide__title"
								:key="'title-' + currentIndex"
								class="
									font-darker-grotesque font-bold
									text-h1-frontpage text-center
									mb-xl
								"
								:class="slides.length && 'mt-auto'"
								v-text="currentTitle"
							></h1>
							<span v-else></span>
						</TransitionExt>

						<!-- Link arrow -->
						<TransitionExt
							name="frontpage-header-wide__content"
							duration="500"
							mode="out-in"
						>
							<span
								v-if="currentLink"
								:key="'arrow-' + currentIndex"
							>
								<span
									:class="[
										'c-frontpage-header-wide__link',
										'inline-flex w-40 h-40',
										'rounded-full bg-white text-text',
									]"
								>
									<SvgArrow class="mx-auto w-20" />
								</span>
							</span>
							<span v-else></span>
						</TransitionExt>

						<nav
							v-if="slides.length > 1"
							class="
								mt-auto
								mb-lg
								self-end
								transform
								>=768:translate-x-2/12col
								flex
								gap-x-sm
							"
						>
							<button
								v-for="(slide, index) in slides"
								:key="index"
								class="
									bg-white
									w-12
									h-12
									-mr-2
									rounded-full
									transition-opacity
									duration-200
								"
								:class="index !== currentIndex && 'opacity-40'"
								:aria-current="
									index === currentIndex ? 'true' : null
								"
								:aria-label="`Gå til slide ${index + 1} af ${
									slides.length
								}`"
								@click="() => goto(index)"
							></button>
						</nav>

						<!-- Flare -->
						<div
							class="
								absolute
								bottom-0
								left-layout-margin
								right-0
								h-5xl
								bg-white
								overflow-hidden
							"
						>
							<LensFlare
								id="c-frontpage-header-wide__flare"
								:class="[
									'c-frontpage-header-wide__lens-flare-desktop',
									'transform -translate-y-1/2 z-10',
								]"
								style="
									width: min(
										var(--visual-viewport-width, 100vw),
										1440
									);
									height: min(
										var(--visual-viewport-width, 100vw),
										1440
									);
								"
								:angle="9"
								:min-blur-amount="12"
								:max-blur-amount="14"
								:easing="(x) => x ** 2.5 * 1.3"
								:flares="[
									{
										size: 800,
										targetSize: 1000,
										offset: 160,
										targetOffset: 180,
										theme: 'primary',
										opacity: 1,
									},
									{
										size: 300,
										offset: -250,
										targetOffset: -300,
										theme: 'secondary',
										opacity: 0.5,
									},
									{
										size: 600,
										targetSize: 400,
										offset: 700,
										targetOffset: 800,
										theme: 'secondary',
										opacity: 0.8,
									},
								]"
							/>
						</div>
					</div>
				</div>
			</LinkTile>
		</div>
	</header>
</template>

<script>
import { imageSizes } from '@limbo-works/vue-umbraco-image';

import LinkTile from '~/citi-baseline/components/LinkTile';
import LensFlare from '~/components/shared/LensFlare';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';

export default {
	name: 'FrontpageHeader',

	components: {
		LinkTile,
		LensFlare,
		UmbracoImageExt,
		SvgArrow,
	},

	props: {
		layout: String,
		slides: Array,
	},

	data() {
		return {
			currentIndex: 0,

			currentHeight: null,
			targetHeight: null,
			frameRequest: null,
		};
	},

	computed: {
		currentSlide() {
			return this.slides?.[this.currentIndex]?.content;
		},

		currentImage() {
			return this.currentSlide?.heroImage;
		},

		currentTitle() {
			return this.currentSlide?.heroTitle;
		},

		currentLink() {
			return this.currentSlide?.heroLink?.[0];
		},
	},

	methods: {
		goto(index) {
			if (this.slides?.length <= 1) {
				this.currentIndex = 0;
				return;
			}

			while (index < 0) {
				index += this.slides.length;
			}
			while (index >= this.slides.length) {
				index -= this.slides.length;
			}

			this.currentIndex = index;
		},

		onSlideLeave() {
			window.setTimeout(() => {
				if (this.$el) {
					this.currentHeight = this.$el.offsetHeight;
				}
			}, 0);
		},
		onSlideEnter() {
			window.setTimeout(() => {
				if (this.$el) {
					this.targetHeight = this.$el.offsetHeight;
					this.animateHeight();
				}
			}, 0);
		},

		animateHeight() {
			this.$el.style.height = `${this.currentHeight}px`;

			window.cancelAnimationFrame(this.frameRequest);
			this.frameRequest = window.requestAnimationFrame(() => {
				if (this.currentHeight === this.targetHeight) {
					this.$el.style.height = null;
					this.currentHeight = null;
					this.targetHeight = null;
				} else {
					const diff = this.targetHeight - this.currentHeight;
					this.currentHeight += diff / 5;
					this.currentHeight =
						Math.round(this.currentHeight * 1000) / 1000;
					if (Math.abs(diff) < 1) {
						this.currentHeight = this.targetHeight;
					}
					this.animateHeight();
				}
			});
		},

		imageSizes,
	},
};
</script>

<style lang="postcss">
.c-frontpage-header-wide {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 557px;
}

@screen >=768 {
	.c-frontpage-header-wide {
		min-height: min(
			100vh -
				var(
					--theme-spacing-site-header,
					var(--theme-spacing-site-header--sm)
				) - 10px,
			749px
		);
	}
}

.c-frontpage-header-wide__image .c-umbraco-image {
	@apply bg-transparent !important;
}

/* Transition for content */
.t-frontpage-header-wide__content-enter-active {
	transition: opacity, transform ease-out;
}
.t-frontpage-header-wide__content-leave-active {
	transition: opacity, transform ease-in;
}
.t-frontpage-header-wide__content-enter,
.t-frontpage-header-wide__content-leave-to {
	opacity: 0;
}
.t-frontpage-header-wide__content-enter {
	transform: translateX(-10px);
}
.t-frontpage-header-wide__content-leave-to {
	transform: translateX(10px);
}
</style>

<template>
	<header v-if="currentSlide" class="c-frontpage-header">
		<div class="w-visual-screen h-full flex-shrink-0 overflow-hidden">
			<div
				class="
					relative
					grid grid-cols-5
					>=768:grid-cols-12
					auto-rows-fr
					gap-x-layout-gutter
					w-full
					h-full
					max-w-layout-max
					px-layout-margin
					mx-auto
				"
			>
				<!-- Lens Flare Desktop -->
				<LensFlare
					id="c-frontpage-header__flare"
					:class="[
						'c-frontpage-header__lens-flare-desktop',
						'absolute left-0 bottom-0 hidden >=768:block',
						'transform translate-y-1/4',
					]"
					style="width: 700px; height: 700px"
					:angle="9"
					:min-blur-amount="20"
					:max-blur-amount="30"
					:easing="(x) => x ** 2.5 * 1.3"
					:flares="[
						{
							size: 1200,
							targetSize: 1000,
							offset: -50,
							targetOffset: -100,
							theme: 'primary',
							opacity: 1,
						},
						{
							size: 800,
							offset: -500,
							targetOffset: -800,
							theme: 'secondary',
							opacity: 0.5,
						},
						{
							size: 600,
							targetSize: 400,
							offset: 600,
							targetOffset: 800,
							theme: 'secondary',
							opacity: 0.8,
						},
					]"
				/>

				<!-- Lens Flare Mobile -->
				<LensFlare
					id="c-frontpage-header__mobile"
					:class="[
						'c-frontpage-header__lens-flare-mobile',
						'absolute right-0 bottom-0 >=768:hidden',
						'transform translate-y-1/4',
					]"
					style="width: 300px; height: 300px"
					:angle="18"
					:min-blur-amount="15"
					:max-blur-amount="15"
					:flares="[
						{
							size: 1400,
							targetSize: 1000,
							offset: -50,
							targetOffset: -100,
							theme: 'primary',
							opacity: 1,
						},
						{
							size: 1000,
							offset: -500,
							targetOffset: -800,
							theme: 'secondary',
							opacity: 0.5,
						},
						{
							size: 800,
							targetSize: 400,
							offset: 600,
							targetOffset: 800,
							theme: 'secondary',
							opacity: 0.8,
						},
					]"
				/>

				<!-- Header Image Desktop -->
				<div
					ref="desktopImage"
					class="
						hidden
						>=768:block
						col-start-4 col-end-13
						row-start-1 row-end-3
					"
					style="min-height: min(200px, 14vw)"
				>
					<!-- The image should go beneath the boks -->
					<div
						class="w-full"
						style="
							height: min(clamp(438px, 72vw, 600px), 250%);
							margin-bottom: -100%;
						"
					>
						<TransitionExt
							name="frontpage-header__content"
							duration="500"
							mode="out-in"
						>
							<UmbracoImageExt
								v-if="currentImage"
								:key="'image-' + currentIndex"
								class="c-frontpage-header__image w-full h-full"
								image-class-names="object-cover"
								:widths="[375, 500, 700, 1040]"
								:sizes="
									imageSizes({
										default: '100vw',
										768: '72vw',
									})
								"
								:alt="currentImage.altText"
								:source-url="currentImage.cropUrl"
								:source-width="currentImage.width"
								:source-height="currentImage.height"
								:aspect-ratio="1040 / 600"
								:lazyload="false"
							/>
							<span v-else></span>
						</TransitionExt>
					</div>
				</div>

				<!-- Header Image Mobile -->
				<div
					ref="mobileImage"
					class="
						>=768:hidden
						col-start-1 col-end-6
						row-start-1 row-end-4
					"
				>
					<!-- The image should go beneath the boks -->
					<div
						class="w-full"
						:style="
							currentImage && 'height: 438px; margin-bottom: -25%'
						"
					>
						<TransitionExt
							name="frontpage-header__content"
							duration="500"
							mode="out-in"
						>
							<UmbracoImageExt
								v-if="currentImage"
								:key="'image-' + currentIndex"
								class="
									c-frontpage-header__image
									-mx-layout-margin
									w-visual-screen
									h-full
								"
								image-class-names="object-cover"
								:widths="[375, 500, 700, 768]"
								:sizes="
									imageSizes({
										default: '100vw',
									})
								"
								:alt="currentImage.altText"
								:source-url="currentImage.cropUrl"
								:source-width="currentImage.width"
								:source-height="currentImage.height"
								:aspect-ratio="375 / 438"
								:lazyload="false"
							/>
							<span v-else></span>
						</TransitionExt>
					</div>
				</div>

				<!-- Header Content -->
				<div
					ref="content"
					class="
						relative
						col-start-1 col-end-6
						>=768:col-end-9
						row-start-4
						>=768:row-start-3
						row-end-7
						>=768:pr-1/12col
					"
				>
					<LinkTile
						:link-tag="currentLink ? 'NuxtLinkExt' : 'div'"
						:aria-labelledby="
							currentLink ? 'frontpage-header__title' : null
						"
						:to="currentLink ? currentLink.url : null"
						:link-partials-query="
							currentLink
								? 'h1, .c-frontpage-header__link'
								: '.nothing'
						"
						class="
							group
							grid grid-cols-7
							gap-x-layout-gutter gap-y-md
							w-full
							h-full
							px-layout-gutter
							>=768:pl-1/12col
							pt-3xl
							pb-4xl
							bg-secondary
							transform
							-translate-x-layout-margin
							>=768:translate-x-0
						"
					>
						<nav
							v-if="slides.length > 1"
							class="
								mt-auto
								>=768:mt-0
								col-span-4
								>=768:col-span-6
								row-start-4
								>=768:row-start-1
								h-40
								flex
								gap-x-sm
							"
						>
							<button
								v-for="(slide, index) in slides"
								:key="index"
								class="
									bg-black
									w-12
									h-12
									mt-auto
									-mr-2
									rounded-full
									transition-opacity
									duration-200
								"
								:class="index !== currentIndex && 'opacity-40'"
								:aria-current="
									index === currentIndex ? 'true' : null
								"
								:aria-label="`Gå til slide ${index + 1} af ${
									slides.length
								}`"
								@click="() => goto(index)"
							></button>
						</nav>

						<!-- Heading -->
						<TransitionExt
							name="frontpage-header__content"
							duration="500"
							mode="out-in"
							@before-leave="onSlideLeave"
							@enter="onSlideEnter"
						>
							<h1
								v-if="currentTitle"
								id="frontpage-header__title"
								:key="'title-' + currentIndex"
								class="
									transform
									duration-500
									group-data-hover:translate-x-8
									font-darker-grotesque font-bold
									text-h1-frontpage
									col-span-6
									row-start-2
								"
								v-text="currentTitle"
							></h1>
							<span v-else></span>
						</TransitionExt>

						<!-- Teaser -->
						<TransitionExt
							name="frontpage-header__content"
							duration="500"
							mode="out-in"
						>
							<p
								v-if="currentTeaser"
								:key="'teaser-' + currentIndex"
								class="
									mt-8
									text-teaser-frontpage
									font-darker-grotesque
									whitespace-pre-wrap
									col-span-6
									row-start-3
								"
								v-html="currentTeaser"
							></p>
							<span v-else></span>
						</TransitionExt>

						<!-- Link arrow -->
						<TransitionExt
							name="frontpage-header__content"
							duration="500"
							mode="out-in"
						>
							<span
								v-if="currentLink"
								:key="'arrow-' + currentIndex"
								class="
									mt-auto
									row-start-4
									>=768:row-start-3
									col-start-7
								"
							>
								<span
									:class="[
										'c-frontpage-header__link',
										'inline-flex w-40 h-40',
										'rounded-full bg-text text-white',
									]"
								>
									<SvgArrow class="mx-auto w-20" />
								</span>
							</span>
							<span v-else></span>
						</TransitionExt>
					</LinkTile>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { imageSizes } from '@limbo-works/vue-umbraco-image';

import LinkTile from '~/citi-baseline/components/LinkTile';
import LensFlare from '~/components/shared/LensFlare';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';

export default {
	name: 'FrontpageHeader',

	components: {
		LinkTile,
		LensFlare,
		UmbracoImageExt,
		SvgArrow,
	},

	props: {
		layout: String,
		slides: Array,
	},

	data() {
		return {
			currentIndex: 0,

			currentHeight: null,
			targetHeight: null,
			frameRequest: null,
		};
	},

	computed: {
		currentSlide() {
			return this.slides?.[this.currentIndex]?.content;
		},

		currentImage() {
			return this.currentSlide?.heroImage;
		},

		currentTitle() {
			return this.currentSlide?.heroTitle;
		},

		currentTeaser() {
			return this.currentSlide?.heroTeaser;
		},

		currentLink() {
			return this.currentSlide?.heroLink?.[0];
		},
	},

	methods: {
		goto(index) {
			if (this.slides?.length <= 1) {
				this.currentIndex = 0;
				return;
			}

			while (index < 0) {
				index += this.slides.length;
			}
			while (index >= this.slides.length) {
				index -= this.slides.length;
			}

			this.currentIndex = index;
		},

		onSlideLeave() {
			window.setTimeout(() => {
				if (this.$el) {
					this.currentHeight = this.$el.offsetHeight;
				}
			}, 0);
		},
		onSlideEnter() {
			window.setTimeout(() => {
				if (this.$el) {
					this.targetHeight = this.$el.offsetHeight;
					this.animateHeight();
				}
			}, 0);
		},

		animateHeight() {
			this.$el.style.height = `${this.currentHeight}px`;

			window.cancelAnimationFrame(this.frameRequest);
			this.frameRequest = window.requestAnimationFrame(() => {
				if (this.currentHeight === this.targetHeight) {
					this.$el.style.height = null;
					this.currentHeight = null;
					this.targetHeight = null;
				} else {
					const diff = this.targetHeight - this.currentHeight;
					this.currentHeight += diff / 5;
					this.currentHeight =
						Math.round(this.currentHeight * 1000) / 1000;
					if (Math.abs(diff) < 1) {
						this.currentHeight = this.targetHeight;
					}
					this.animateHeight();
				}
			});
		},

		imageSizes,
	},
};
</script>

<style lang="postcss">
.c-frontpage-header {
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-frontpage-header__image .c-umbraco-image {
	@apply bg-transparent !important;
}

/* Transition for content */
.t-frontpage-header__content-enter-active {
	transition: opacity, transform ease-out;
}
.t-frontpage-header__content-leave-active {
	transition: opacity, transform ease-in;
}
.t-frontpage-header__content-enter,
.t-frontpage-header__content-leave-to {
	opacity: 0;
}
.t-frontpage-header__content-enter {
	transform: translate(-12px, -8px);
}
.t-frontpage-header__content-leave-to {
	transform: translate(12px, 8px);
}
</style>
